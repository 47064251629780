<template>
	<div>
		<div class="showpic-index" :style="thisStyle">
			<img :src="imageUrl" :style="thisStyle"/>
			<div class="showpic-index-cover" :style="thisStyle">
				<div class=" flex center alcenter" style="height: 100%;" @click="showpic()">
					<a-icon type="search" class="cl-w ft20 clickAct" />
				</div>
			</div>
		</div>
		<div class="showpic-index-vw" v-if="visible">
			<div class=" flex center alcenter" style="height: 100%;">
					<img :src="imageUrl"/>
			</div>
			<div class="showpic-index-vw-close">
				<a-icon type="close-circle" class="cl-w ft30 clickAct" @click="closeShow()"/>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			imageUrl: {
				type: String,
				default: ''
			},
			thisWidth: {
				type: Number,
				default: 120
			},
			thisHeight: {
				type: Number,
				default: 120
			}
		},
		data(){
			return{
				loading: false,
				visible:false,
				thisStyle: "width:" + this.thisWidth + "px; height:" + this.thisHeight + "px;"
			}
		},
		methods:{
			showpic(){
				this.visible=true;
			},
			closeShow(){
				this.visible=false;
			}
		}
	}
</script>

<style>
	.showpic-index{
		position: relative;
	}
	.showpic-index-cover{
		display: none;
		background: rgb(0, 0, 0, 0.4);
		position: absolute;
		top:0;
	}
	.showpic-index:hover .showpic-index-cover{
		display: block;
	}
	
	.showpic-index-vw{
		background: rgb(0, 0, 0, 0.4);
		width: 100vw;
		height: 100vh;
		position: fixed;
		top:0;
		left:0;
		z-index:999
	}
	
	.showpic-index-vw-close{
		position: fixed;
		top:10px;
		right:10px;
		z-index:999
	}
</style>
