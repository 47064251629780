<template>
	<div>
		<div class="ft20 cl-main ftw600">业绩核准</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="员工">
						<a-input v-model="search.staff" placeholder="请输入员工姓名/手机号"></a-input>
					</a-form-item>

					<a-form-item label="客户">
						<a-input v-model="search.member" placeholder="请输入客户姓名/手机号"></a-input>
					</a-form-item>

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="pd30 bg-w " style="min-height: 800px;">
				<div>
					<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
						<a-radio-button :value="1">
							待核准
						</a-radio-button>
						<a-radio-button :value="8">
							核准通过
						</a-radio-button>
						<a-radio-button :value="-1">
							核准不过
						</a-radio-button>
					</a-radio-group>
				</div>
				<div class="mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="performance_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
							:data-source="datas" :loading="loading">

							<div  slot="staff" slot-scope="staff,record">
								<div class="flex center" v-if="staff">
									{{staff.name}}｜{{staff.mobile}}
								</div>
								<div class="flex alcenter" v-else>-</div>
							</div>


							<div class="flex center"  slot="items" slot-scope="items,record">
								<div v-if="items.length>0" style="width: 70%;">
									<div class="flex alcenter space" v-for="(item,index) in items">
										<div>{{item.type_name}}</div>
										<div class="">¥{{item.single_commission}}</div>
										<div class="">*{{item.num}}</div>
									</div>
								</div>
								<div v-else>-</div>
							</div>

							<div class="flex center cl-theme" slot="commission" slot-scope="commission,record">
								¥{{commission}}
							</div>

							<div class="flex center"  slot="pictures" slot-scope="pictures,record">
								<div v-if="pictures.length>0">
									<div class="flex wrap">
										<div class="mr10 mb10" v-for="(item,index) in pictures">
											<show-pic :imageUrl="item" :thisWidth="60" :thisHeight="60"></show-pic>
										</div>
									</div>
								</div>
								<div v-else>-</div>
							</div>



							<template slot="action" slot-scope="record,index">
								<div class="flex center" v-if="record.status==1">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;"  @click="approvedAct(record)">
													<i class="iconfont ft14 iconyes"></i>
													<span class="ml10">核准通过</span>
												</a>
											</a-menu-item>
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="notApprovedAct(record)">
													<i class="iconfont ft14 iconno"></i>
													<span class="ml10">核准不过</span>
												</a>
											</a-menu-item>
										</a-menu>
									</a-dropdown>
								</div>

								<div class="flex center" v-else>
									{{record.status_means}}
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../common/mixin/list.js';
	import showPic from './components/PerformanceApproval/modal/showpic.vue';
	export default {
		mixins: [listMixin],
		components:{
			showPic
		},
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					staff:'',
					member:'',
					status:1,
				},
				columns: [
					{title: '员工',dataIndex: 'staff',align: 'center',width:245,scopedSlots: {customRender: 'staff'}},
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '客户手机',dataIndex: 'mobile',align: 'center',ellipsis: true},
					{title: '业绩填单类型',dataIndex: 'items',align: 'center',width:300,scopedSlots: {customRender: 'items'}},
					{title: '业绩提成',dataIndex: 'commission',align: 'center',scopedSlots: {customRender: 'commission'}},
					{title: '员工备注',dataIndex: 'remark',align: 'center',width:245},
					{title: '图片',dataIndex: 'pictures',align: 'center',width:245,scopedSlots: {customRender: 'pictures'}},
					{title: '状态操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/getPerformanceList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					member:this.search.member,
					staff:this.search.staff,
					status:this.search.status,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			approvedAct(record){
				this.$confirm({
					title:'确认核准通过这个业绩吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/approvalPerformance',{
								performance_id:record.performance_id,
								status:8,
							}).then(res=>{
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			notApprovedAct(record){
				this.$confirm({
					title:'确认核准不通过这个业绩吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/approvalPerformance',{
								performance_id:record.performance_id,
								status:-1,
							}).then(res=>{
								this.$message.success('操作成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			changeStatus(){
				this.pagination.current=1;
				this.getLists();
			},

			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.performance-approval-picture{

	}
</style>
